import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import "./workdetail.scss";

const WorkDetail = () => {
  return (
    <div className="work-detail">
      WorkDetail
      <IoCloseOutline />
    </div>
  );
};

export default WorkDetail;
